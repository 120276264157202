import React from 'react'


const BankDescriptionModal = ({ modalData }) => {

    return (
        <>
            <div className="mt-3 cm-create-task-form px-4 pb-4">
                <div className='d-flex gap-2'>
                    <span className='cm-sm-txt mb-2 fw-medium'>{modalData.reviewBankDesc}</span>
                </div>
            </div>
        </>
    )

}

export default BankDescriptionModal;