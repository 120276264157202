import React from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateBankModal from "./CreateBankModal";
import instance from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { appError } from '../../redux/actions/error.action';
import { getApiUrl } from '../../utils/apiUrls';
import BankBox from "./BankBox.Component";
import Pagination from '@mui/material/Pagination';
import { Stack } from "react-bootstrap";
import AnimationDataLoader from "../../components/Loaders/animationData";
import NoResultsPage from "./NoResultsPage.component";
import "../../containers/my-bank/MyBank.css"
import BankSettingModal from "./BankSettingModal.component";
import CustomDialog from "../../components/custom-dialog/CustomDialog.component";
import NumberedPagination from "../../components/numbered-pagination/NumberedPagination.component";
import { apiErrorHandler } from "../../utils/errorHandler";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { useTranslation } from "react-i18next";
import useDebounce from "../../hooks/useDebounce";
import { saveAs } from "file-saver";

const pageSize = 20;

const MyBank = () => {
  const breadcrumbData = [
    {
      iconClass: regular("house"),
      link: "/",
    },
    {
      label: "My Bank",
      link: "/",
    },
  ];

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const user = useSelector((state) => state.user);
  const [bankData, setBankData] = React.useState({
    currentQuest: null,
    offset: 0,
    pageNum: 0,
    currentQuestPage: 0,
    previous: false,
    next: true,
    totalQuestions: 1,
    isLoading: false,
    dataMap: null,
    searchActive: false
  });

  const [inputVal, setInputVal] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState({
    status: false,
    data: null
  })

  const [modalData, setModalData] = React.useState({
    type: null,
    data: null
  })

  const dispatch = useDispatch();
  const buttonName = "Create Bank"

  // DeBounce Function
  useDebounce(() => {
    if ((inputVal.length >= 3 || inputVal === '')) getAllBanks(0);
  }, [inputVal], 800);

  const getAllBanks = async (pageNum = 0) => {
    try {
      setBankData({
        ...bankData,
        isLoading: true,
        currentQuest: null,
        pageNum
      })
      const res = await instance.get(`${getApiUrl('getAllBanks')}?offset=${pageNum}&pageSize=${pageSize}${inputVal ? `&bankName=${inputVal}` : ''}`);

      const currentQuest = res.data.bankData;

      if (res.data) {
        setBankData({
          ...bankData,
          currentQuest,
          totalQuestions: Math.ceil(res.data.totalBanks / pageSize),
          currentQuestPage: pageNum,
          isLoading: false,
          searchActive: !!inputVal
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      appError('', error, dispatch);
    }
  }
  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const handleInpChange = (value) => {
    setInputVal(value);
  }

  const handleBankModalToggle = (type, data = null) => {
    let dataToSet = {
      type: type ? type : null,
      data
    };

    if (type === "RENAME_BANK_CLOSE") {
      setBankData({
        ...bankData,
        currentQuest: bankData.currentQuest.map(el => {
          if (el.reviewBankId === data.reviewBankId) {
            el.reviewBankName = data.bankName
          }

          return el;
        })
      })
      dataToSet = { type: null, data: null };
    }

    if (type === "DELETE_BANK_CLOSE") {
      setBankData({
        ...bankData,
        currentQuest: bankData.currentQuest.filter(el => el.reviewBankId !== data.reviewBankId)
      })
      dataToSet = { type: null, data: null };
    }

    if (type === "ASSIGN_BANK_CLOSE") {
      setBankData({
        ...bankData,
        currentQuest: bankData.currentQuest.map(el => {
          if (el.reviewBankId === data.reviewBankId) {
            if (el.assignedTo.length > 0) {
              let userIds = new Set(el.assignedTo.map(d => d.assignedTo));
              el.assignedTo = [...el.assignedTo, ...data.assignedUsers.filter(d => !userIds.has(d.assignedTo))];
            } else {
              el.assignedTo = data.assignedUsers
            }
            el.assignedCount = el.assignedTo.length;
          }

          return el;
        })
      })
      dataToSet = { type: null, data: null };
    }


    if (type === "DELETE_BANK_ASSIGNMENT_SUCCESS") {
      setBankData({
        ...bankData,
        currentQuest: bankData.currentQuest.map(el => {
          if (el.reviewBankId === data.reviewBankId) {
            el.assignedTo = data.assignedUsers
            el.assignedCount = data.assignedUsers.length;
          }

          return el;
        })
      });
      if (data.assignedUsers.length > 0) {
        dataToSet = modalData;
      } else {
        dataToSet = { type: null, data: null };
      }
    }

    setModalData(dataToSet)
  }

  const handleDelete = async (reviewBankId) => {
    try {
      const response = await instance.get(`${getApiUrl('deleteRootBank')}?bankId=${reviewBankId}`);

      if (response.data) {
        setBankData({
          ...bankData,
          currentQuest: bankData.currentQuest.filter(el => el.reviewBankId !== reviewBankId)
        })
      }
    } catch (error) {
      const errObj = apiErrorHandler(error);
      dispatch(openSnackBar({
        msg: t(errObj.statusText),
        type: 'error'
      }))
    }
  }

  const handleDialogToggle = (data) => {
    setDeleteDialog({
      ...deleteDialog,
      status: !deleteDialog.status,
      data: !!data ? data : null
    })
  }

  const dialogActionFn = () => {
    handleDelete(deleteDialog.data.reviewBankId);
    handleDialogToggle();
  }



  const loadAllBanks = () => (bankData.currentQuest?.map((element, i) => <BankBox role={user.role} handleDialogToggle={handleDialogToggle} key={i} index={element.reviewBankId} data={element} handleModalToggle={handleBankModalToggle} />))

  const handlePagination = (page) => {
    let currentQuestPage = bankData.currentQuestPage;
    if (page === 'next') {
      currentQuestPage = bankData.currentQuestPage + 1;
    } else if (page === 'prev') {
      currentQuestPage = bankData.currentQuestPage - 1;
    } else {
      currentQuestPage = page - 1;
    }

    getAllBanks(currentQuestPage, currentQuestPage);
  }

  return (
    <div className="pb-4 cm-my-bank-body">
      <Breadcrumb data={breadcrumbData} isButtonOption={user.role === "ADMIN"} buttonName={buttonName} handleToggleModal={handleToggleModal} />
      <div className="d-flex py-2 px-4 mx-3">
        <span className="text-blue-800 cm-mmc-font cm-sm-txt fw-medium  m-0 my-auto me-2">
          Search:
        </span>
        <div className="my-auto ms-2">
          <input
            type="text"
            value={inputVal}
            onChange={(e) => handleInpChange(e.target.value)}
            name="search"
            placeholder={"Enter Bank name"}
            className="user-input_search-bar cm-btn-radius pe-2"
            id="skillDashSearch"
          />
          <FontAwesomeIcon
            icon={regular("search")}
            className="user-input_icon"
          />
        </div>
      </div>

      <div className="cm-bankcard-container d-flex flex-wrap">
        {!bankData?.isLoading ? (bankData?.currentQuest?.length !== 0 ? loadAllBanks() : <NoResultsPage t={t} searchActive={bankData.searchActive} searchTerm={inputVal} resetFn={() => setInputVal('')} />) : (<div className="d-flex cm-question-progress-bar justify-content-center align-items-center">
          <AnimationDataLoader />
        </div>)}
      </div>

      {
        bankData.currentQuest?.length > 0 && <>
          <CustomDialog
            dialogHeading="Delete Question Bank"
            cancelBtnLabel="No Close Panel"
            actionBtnBg="bg-danger"
            actionBtnLabel={<span>Delete Bank</span>}
            cancelFn={handleDialogToggle}
            actionFn={dialogActionFn}
            dialogStatus={deleteDialog.status}
          >
            <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to delete <span className="fw-semibold">"{deleteDialog?.data?.bankName}"</span>?</p>
          </CustomDialog>
          <BankSettingModal role={user.role} handleBankModalToggle={(type, data) => handleBankModalToggle(typeof type === 'string' ? type : null, data ? data : null)} modalData={modalData} />
        </>
      }

      <div className=" pt-2 cm-event-page">
        <div className="cm-event-pagenumbers d-flex">
          {!isLoading && <NumberedPagination showNextPrevButtons={true} handlePagination={handlePagination} totalPages={bankData.totalQuestions} pagesToShow={bankData.totalQuestions < pageSize ? bankData.totalQuestions : 10} currentPage={bankData.currentQuestPage + 1} />}
        </div>
      </div>
      <CreateBankModal role={user.role} modalStatus={isModalOpen} handleModalToggle={handleToggleModal} getAllBanks={getAllBanks} />
    </div>
  );
};

export default MyBank;
