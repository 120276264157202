import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import useWindowSize from '../../hooks/useWindowSize';
import { ReactComponent as EmptyTaskIcon } from '../../assets/images/home/empty-data.svg';
import ReviewTimelineItem from '../../components/reviewer-item/ReviewTimelineItem.component';
import instance from '../../utils/axios';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { apiErrorHandler } from '../../utils/errorHandler';
import { getApiUrl } from '../../utils/apiUrls';
import AnimationDataLoader from '../../components/Loaders/animationData';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';
import { TextareaAutosize } from '@mui/material';

const ReviewTimelineModal = ({ t, reviewModalStatus, handleModalToggle, timelineQuestionId, timelineQuestionStatus }) => {
    const dispatch = useDispatch();
    const windowSize = useWindowSize();

    const modalHeadRef = React.useRef(null);
    const modalFootRef = React.useRef(null);
    const [contentHeight, setContentHeight] = React.useState(null);
    const [isLoading,setIsLoading]=React.useState(false);
    const [state, setState] = React.useState({
        data: [],
        isLoading: false
    });

    const [comment, setComment] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");

    const fetchTasks = async () => {
        setState({
            data: [],
            isLoading: true
        })
        instance.get(`${getApiUrl('getCodelysisQuestionCommentData')}/${timelineQuestionId}`)
            .then(function (response) {
                if (response.data) {
                    setState({
                        data: response.data,
                        isLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                const errObj = apiErrorHandler(error);
                dispatch(openSnackBar({
                    msg: t(errObj.statusText),
                    type: 'error'
                }))
            });

    }

    React.useEffect(() => {
        if (reviewModalStatus)
            fetchTasks();
    }, [reviewModalStatus])

    React.useEffect(() => {
        setTimeout(() => {
            if (!!modalHeadRef.current && !!modalFootRef.current) {
                setContentHeight(window.innerHeight - (modalHeadRef.current.clientHeight + modalFootRef.current.clientHeight));
            }
        }, 100)
        setErrorMsg("");
    }, [reviewModalStatus])

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        handleModalToggle(open);
        if (!open) setState({
            data: [],
            isLoading: false,
        })
    };

    const loadTaskItems = () => {
        return state.data.map((el) => {
            return <ReviewTimelineItem t={t} key={el.id} data={el} />
        })
    }

    const handleComment = (e) => {
        setComment(e.target.value)

    }

    const saveComment = () => {
        setErrorMsg("");
        if (comment.trim().length == 0) {
            setErrorMsg("Comment cannot be empty");
        } else {
            if (timelineQuestionStatus !== "ReadyForAdmin") {
                setState({
                    ...state,
                    isLoading: true
                })
                setIsLoading(true)
                const data = { questionId: timelineQuestionId, comment: comment }
                instance.post(`${getApiUrl('saveCodelysisQuestionComment')}`, data)
                    .then(function (response) {
                        if (response.data) {
                            setComment("");
                            fetchTasks();
                            dispatch(openSnackBar({
                                msg: "The comment is marked for the question.",
                                type: 'success'
                            }))

                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        const errObj = apiErrorHandler(error);
                        dispatch(openSnackBar({
                            msg: t(errObj.statusText),
                            type: 'error'
                        }))
                    });
            }
        }
    }


    const list = (anchor) => (
        <Box
            sx={{ width: windowSize.screenWidth <= 991 ? 'auto' : 800 }}
            role="presentation"
        >
            <div className="cm-taskdetail-modal-header d-flex justify-content-between align-items-center row" ref={modalHeadRef}>
                <div className="col-lg-11 d-flex justify-content-start">
                    <p className='my-0 fw-medium'>Review Timeline</p>
                    <span className="cm-refresh-modal-task-btn d-flex align-items-center justify-content-center bg-white cm-pointer">
                        <FontAwesomeIcon icon={regular("refresh")} className="text-blue-gray-700 ms-3" onClick={() => fetchTasks()} />
                    </span>
                </div>
                <div className="col-lg-1 justify-content-end">
                    <FontAwesomeIcon icon={regular("times")} className="text-blue-800 cm-pointer ms-4 " onClick={toggleDrawer(false)} />
                </div>
            </div>
            <div className={`cm-taskdetail-modal-content cm-sm-txt text-primary position-relative`} style={{ height: !!contentHeight ? `${contentHeight}px` : 'auto', overflow: "auto" }}>
                <div className="cm-add-task-content-header d-flex justify-content-between align-items-center">
                </div>
                {
                    state.isLoading ?
                        <div className='d-flex justify-content-center'><AnimationDataLoader /></div> :
                        state.data.length == 0 ?
                            <div className="cm-content mt-3 text-center pt-5 cm-add-task-modal-empty position-absolute">
                                <EmptyTaskIcon />
                                <p className='text-blue-700 fw-semibold my-0 pb-2'>Nothing to see!</p>
                                <p className="my-0 text-blue-gray-500 cm-sm-txt fw-medium">You can start by adding a Comment.</p>
                            </div> :
                            <div className="cm-content">
                                {loadTaskItems()}
                            </div>
                }
            </div>
            <div className="cm-taskdetail-modal-footer" ref={modalFootRef}>
                <span className='cm-xs-txt text-danger fw-medium pb-2 ms-2'>&nbsp;&nbsp;{errorMsg}</span>
                <TextareaAutosize
                    maxRows={12}
                    minRows={4}
                    className={`text-blue-800 cm-sm-txt p-2 `}
                    disabled={`${timelineQuestionStatus === 'ReadyForAdmin' || state.isLoading ? "disabled" : ""}`}
                    aria-label="maximum height"
                    placeholder="Write your comment"
                    value={comment}
                    style={{
                        'width': '750px', 'border': '1px solid var(--bs-blue-gray-100)', '&:hover': {
                            borderColor: '#FFFFFF',
                            borderWidth: 2
                        },
                        maxHeight: '160px'
                    }}
                    onChange={handleComment}
                />
                <div className="cm-taskdetail-modal-footer-bottom d-flex">
                    <div className="d-flex flex-column">
                        <div className="ms-2 cm-event-progress-controls d-flex align-items-center " >
                            <CustomButtonLoader
                                showLoadingState ={state.isLoading}
                                colorTheme= "blue"
                                icon={regular('angle-right')}
                                reverseIconDirection={false}
                                buttonLabel={t("save_comment")}
                                buttonStyle={`px-12 py-10 cm-mar-left-icon`}
                                handleOnClick={saveComment}
                            />
                            <button className='ms-2 btn cm-trans-btn text-action-blue cm-sm-txt fw-medium' onClick={toggleDrawer(false)}>
                                {t('discard')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );

    return (
        <div className='cm-add-task-modal-container'>
            {/* <span className='cm-add-task-trigger text-blue-800 d-flex align-items-center justify-content-center bg-white cm-pointer' onClick={toggleDrawer(true)}>
                <span className='cm-sm-txt fw-medium'>Review Timeline</span>
                <FontAwesomeIcon icon={regular("angle-right")} className="ps-2" />
            </span> */}
            <div>
                <React.Fragment>
                    <Drawer
                        anchor={'right'}
                        open={reviewModalStatus}
                        onClose={toggleDrawer(false)}
                        className="cm-tasklist-modal-wrapper"
                    >
                        {!!reviewModalStatus ? list('right') : <div className='py-4 d-flex align-items-center justify-content-center'><CircularProgress /></div>}
                    </Drawer>
                </React.Fragment>
            </div>
        </div>
    )

}

export default ReviewTimelineModal;