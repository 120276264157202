import React from "react";
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { TextareaAutosize } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Select as Muiselect } from '@mui/material';
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import "./QuestionLibraryKnowledgeBased.css"
import AnimationDataLoader from "../../components/Loaders/animationData";
import { addIframeLazyLoading, getInitials } from "../../utils/helperFn";
import moment from "moment";
import NoResultsPage from "../../pages/my-bank/NoResultsPage.component";
import { Link, useNavigate } from "react-router-dom";
import { getApiUrl } from "../../utils/apiUrls";
import { apiErrorHandler } from "../../utils/errorHandler";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import MenuItem from '@mui/material/MenuItem';
import instance from "../../utils/axios";
import { useSelector } from "react-redux";
import CustomDialog from "../../components/custom-dialog/CustomDialog.component";

const BankQuestionPreviewCard = ({ question, role, permissions, commentModal, handleCommentModalToggle, isCommentModalOpen, t, handleSaveComment, handleFormView, questionType, bankId, checkedQuestions, setCheckedQuestions, setAllChecked, totalQuestions, selectRef, handleSelectedDelete, getPermission, bankName }) => {
    const navigate = useNavigate();
    const [formVal, setFormVal] = React.useState({
        reason: null,
        comment: '',
        bottomBarHt: null
    });
    const [commentFilter, setCommentFilter] = React.useState('all')
    const [flagCount, setFlagCount] = React.useState(question.flagCount);
    const [commentMap, setCommentMap] = React.useState(new Map());
    const [comments, setComments] = React.useState(commentModal?.comments);
    const user = useSelector((state) => state.user);
    const [isResolved, setIsResolved] = React.useState(false);
    const bottomCont = React.useRef(null);
    const dispatch = useDispatch();
    const istrue = (question.reviewStatus == null || question.reviewStatus === false) ? false : true;
    const [reviewStatus, setReviewStatus] = React.useState(istrue);
    React.useEffect(() => {
        if (bottomCont.current) {
            setFormVal({ ...formVal, bottomBarHt: bottomCont.current.offsetHeight })
        }
    }, [bottomCont])
    const commentOptions = React.useMemo(() => ([
        { label: "ALL", value: 'all' },
        { label: "Resolved", value: 'resolved' },
        { label: "UnResolved", value: 'unresolved' }
    ]), []);

    // console.log(flag,version);

    const [dialogStatus, setDialogStatus] = React.useState(false);

    const handleMarkFlag = () => {
        if (formVal.reason && formVal.comment && formVal.comment.length <= 500) {
            const dataToSend = {
                commentData: formVal.comment,
                flagId: formVal.reason.flagId
            }

            handleSaveComment(question.reviewQuestionId, dataToSend);
            setFormVal({ reason: null, comment: '' });
            setFlagCount(flagCount + 1);

        } else {
            let errorTxt = 'Error occurred while saving your comment. Please check in sometime.'
            if (!formVal.reason) {
                errorTxt = "Flag cannot be empty";
            }
            else if (!formVal.comment) {
                errorTxt = "Comment cannot be empty."
            }
            else if (formVal.comment) {
                errorTxt = "Comment cannot be greater than 500 characters."
            }
            handleSaveComment(question.reviewQuestionId, null, errorTxt)
        }
    }

    React.useEffect(() => {
        const hashMap = new Map();
        commentModal?.comments?.map(element => {
            hashMap.set(element.commentId, element.resolved)
        });
        setCommentMap(hashMap)
        setComments(commentModal.comments)
    }, [commentModal?.comments])


    const handleFormChange = (key, value) => {
        setFormVal({
            ...formVal,
            [key]: value
        })
    }
    const loadSelectMenuItem = (list) => list.map(el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)

    const handleCloseModal = () => {
        setFormVal({ reason: null, comment: '' })
        handleCommentModalToggle(null)
    }

    const handleResolveComment = async (commentId) => {
        setFlagCount(flagCount - 1);
        try {
            const res = await instance.post(`${getApiUrl('resolveComment')}/${commentId}`)
            if (res.data.status) {
                dispatch(openSnackBar({
                    msg: "Comment Resolved",
                    type: 'success'
                }));
            } else {
                throw Error(res.data);
            }
        }
        catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(
                openSnackBar({
                    msg: t(errObj.statusText),
                    type: "error",
                })
            );
        }
        let newmap = new Map(commentMap)
        newmap.set(commentId, true);
        setCommentMap(newmap);
    }

    const handleChangeReviewQuestion = async (questionId) => {
        setReviewStatus(!reviewStatus);
        try {
            let dataToSend = {
                reviewQuestionId: questionId,
                reviewStatus: !reviewStatus
            }
            const res = await instance.post(`${getApiUrl('changeReviewStatus')}`, dataToSend)
            if (res.data.status) {
                dispatch(openSnackBar({
                    msg: reviewStatus ? "Question Marked as UnReviewed" : "Question Marked as Reviewed",
                    type: 'success'
                }));
            } else {
                throw Error(res.data);
            }
        }
        catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(
                openSnackBar({
                    msg: t(errObj.statusText),
                    type: "error",
                })
            );
        }
    }
    const handleChangeFilter = (e) => {
        setCommentFilter(e.target.value);
        let filterComments = commentModal?.comments.filter((comment) => {
            if (e.target.value === 'resolved') return (commentMap.get(comment.commentId) === true);
            else if (e.target.value === 'unresolved') return commentMap.get(comment.commentId) === false;
            else return comment;
        })
        setComments(filterComments);
    }

    const handleSingleSelectQuestion = (event, questionId) => {
        const previousCheckedCount = checkedQuestions?.length;
        if (event.target.checked) {
            setCheckedQuestions([...checkedQuestions, questionId]);
            if (totalQuestions === previousCheckedCount + 1) {
                selectRef.current.indeterminate = false;
                setAllChecked(true);
            }
            else selectRef.current.indeterminate = true;
        }
        else {
            const filteredCheckedQuestions = checkedQuestions?.filter((element) => element !== questionId);
            setCheckedQuestions(filteredCheckedQuestions);
            setAllChecked(false);
            if (previousCheckedCount - 1 === 0) selectRef.current.indeterminate = false;
            else selectRef.current.indeterminate = true;
        }
    }

    const handleDelete = (questionId) => {
        const questionIds = [questionId];
        handleSelectedDelete(questionIds);
    }

    const handleViewQuestion = (questionId) => {
        navigate(`/viewQuestion?question_id=${question.reviewQuestionId}`,
            { state: { bank: { name: bankName, id: bankId }, question: { difficultyLevel: question.difficultyLevel, topic: question.bankName } } });
    }

    const handleDialogToggle = () => {
        setDialogStatus(false);
    }

    const dialogActionFn = () => {
        setDialogStatus(false);
        handleDelete(question.reviewQuestionId);
    }

    return (
        <div className={`bank-question-card border-gray-300 border rounded pt-3 bg-white mb-4 position-relative ${isCommentModalOpen ? "cm-comment-modal-active" : ""} overflow-hidden`}>
            <div className='cm-left-col d-flex flex-column justify-content-between px-3'>
                <div className="bank-ques-id">
                    <div className="d-flex gap-2">
                        <input type="checkbox" name="question-single-select" id="question-single-select"
                            checked={checkedQuestions.includes(question.reviewQuestionId)}
                            onChange={(e) => handleSingleSelectQuestion(e, question.reviewQuestionId)}
                            style={{ transform: "scale(1.1)", cursor: "pointer" }}
                        />
                        <span className='ques-id-txt fw-semibold'>Question Id : {question.reviewQuestionId}</span>
                    </div>
                    <div className='ques-text'>
                        {question?.questionText !== null && <div dangerouslySetInnerHTML={{ __html: addIframeLazyLoading(question.questionText) }}></div>}
                    </div>

                    <div className='my-3'>
                        {question?.questionJson?.choices.map((element, index) => {
                            return (<div key={index} className='my-2 answer-text d-flex align-items-start'>
                                {question.displayType === "MCQ" ?
                                    <input className={`inline-block mt-1 mx-3 cm-pointer ${'disabledClass'}`}
                                        type="radio"
                                        value={index}
                                        name={'answerOptions' + question.reviewQuestionId}
                                        defaultChecked={question.questionJson.correctChoiceIndex === index + 1 && getPermission("canViewSolution")}
                                        disabled={true}
                                    />
                                    :
                                    <input className='inline-block mt-1'
                                        type="checkbox"
                                        value={index}
                                        name={'answerOptions' + question.reviewQuestionId}
                                        disabled={true}
                                        defaultChecked={question.questionJson.correctChoiceIndices?.includes(index + 1) && getPermission("canViewSolution")}
                                    />
                                }
                                <div className='w-100 mx-2' dangerouslySetInnerHTML={{ __html: addIframeLazyLoading(element.choiceText) }} />
                            </div>)
                        })}
                    </div>
                    {question.answerDescription && getPermission("canViewSolution") &&
                        <div>
                            <label className="fw-semibold mb-2">Answer Description</label>
                            <div className='w-100 mx-2' dangerouslySetInnerHTML={{ __html: addIframeLazyLoading(question.answerDescription) }} />
                        </div>
                    }
                </div>
            </div>
            <div className='mcqCardConatinerBottom w-100 border-top border-blue-gray-100 pt-2 pb-1' ref={bottomCont}>
                <div className='mcqCardConatinerBottomTags mcqCardConatinerBottomTagsPreviewCard d-flex align-items-center flex-wrap ps-3'>
                    <span className='mcq-tags mb-1'>{question.difficultyLevel}</span>
                    <span className='mcq-tags mb-1'>{question.displayType}</span>
                    <span className='mcq-tags mb-1'>{question.bankName}</span>
                </div>

                {user.role === "ADMIN" &&
                    <div className="cm-flag-icon user-select-none" >
                        <CustomToolTip title={"Question history"}>
                            <p className={`text-action-blue ${question.isEdited ? 'cm-pointer' : 'cm-pointer-none cm-disabled'} mb-0 me-2`} onClick={() => handleViewQuestion(question.reviewQuestionId)}>
                                <FontAwesomeIcon icon={regular("clock-rotate-left")} style={{ fontSize: '16px' }} />
                            </p>
                        </CustomToolTip>
                    </div>
                }
                <div className="cm-flag-icon user-select-none">
                    <CustomToolTip title={"Edit"}>
                        <Link
                            className={`text-action-blue fw-medium position-relative mb-0 me-2 ${getPermission("canEditQuestion") ? "cm-pointer" : "cm-pointer-none cm-disabled"}`}
                            to={`/reviewQuestion/editQuestion/${question.reviewQuestionId}`}
                            state={{ isBank: true, data: question, questionType: questionType, reviewBankId: bankId }}
                        >
                            <FontAwesomeIcon icon={regular("pen-to-square")} style={{ fontSize: '16px' }} />
                        </Link>
                    </CustomToolTip>
                </div>
                <div className="cm-flag-icon user-select-none">
                    <CustomToolTip title={"Delete"}>
                        <p className={`cm-pointer mb-0 me-2 ${getPermission("canDeleteQuestion") ? "cm-pointer" : "cm-pointer-none cm-disabled"}`} onClick={() => { setDialogStatus(true) }}>
                            <FontAwesomeIcon icon={regular("trash")} className="text-action-blue" style={{ fontSize: '16px' }} />
                        </p>
                    </CustomToolTip>
                </div>
                <div className="cm-flag-icon user-select-none" >
                    <CustomToolTip title={"Flags"}>
                        <p className={`position-relative mb-0 me-2 ${getPermission("canViewComment") || getPermission("canComment") ? "cm-pointer" : "cm-pointer-none cm-disabled"}`} onClick={() => { handleCommentModalToggle(question.reviewQuestionId) }}>
                            <FontAwesomeIcon icon={faFlag} className='flagIcon ' style={{ fontSize: '16px', color: 'rgb(255, 24, 65)' }} />
                            {flagCount > 0 && <span className="position-absolute bg-action-blue text-white cm-xs-txt d-flex align-items-center justify-content-center" style={{ width: '16px', height: "16px", borderRadius: '50%', top: "-7.5px", right: "-12px" }}>{flagCount}</span>}
                        </p>
                    </CustomToolTip>
                </div>
                <div className="cm-flag-icon user-select-none" >
                    <CustomToolTip title={"Review question"}>
                        <p className="cm-pointer mb-0 me-2" onClick={() => handleChangeReviewQuestion(question.reviewQuestionId)}>
                            <FontAwesomeIcon icon={reviewStatus ? solid("circle-check") : regular("circle-check")} className={reviewStatus ? "text-success" : "text-blue-gray-700"} style={{ fontSize: '16px' }} />
                        </p>
                    </CustomToolTip>
                </div>

                <CustomDialog
                    dialogHeading="Delete Question"
                    cancelBtnLabel="No, Don't Delete"
                    actionBtnLabel={<span>Delete Question</span>}
                    actionBtnBg="bg-danger"
                    cancelFn={handleDialogToggle}
                    actionFn={dialogActionFn}
                    dialogStatus={dialogStatus}
                >
                    <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to delete this question?</p>
                </CustomDialog>
            </div>
            {isCommentModalOpen && <div className="cm-right-col border-start border-blue-gray-100 overflow-hidden" style={{ height: formVal.bottomBarHt ? `calc(100% - ${formVal.bottomBarHt}px)` : '100%' }}>
                <div className={`px-3 pt-3 overflow-auto cm-comment-row-1 cm-sm-txt`}>
                    {(
                        getPermission("canComment") &&
                        <>
                            <div className="mt-4 cm-event-flag-container">
                                <Select
                                    className='w-100 text-blue-800 fw-medium cm-sm-txt select-flag-bar'
                                    options={commentModal.flagData}
                                    placeholder="---Select a Flag---"
                                    onChange={(selected) => handleFormChange("reason", selected)}
                                    value={formVal.reason}
                                />
                                <div className='d-flex'>
                                    <TextareaAutosize
                                        maxRows={12}
                                        minRows={4}
                                        className="mt-4 w-100  cm-input-text-area cm-flag-comment form-control text-blue-800 fw-medium cm-sm-txt p-2"
                                        placeholder={"Write your comment to select flag"}
                                        value={formVal.comment}
                                        onChange={(e) => handleFormChange("comment", e.target.value)}
                                    />
                                </div>
                                <div className="cm-form-btn-group mt-2 pt-2 d-flex align-items-center justify-content-end">
                                    <button className="border-0 ms-2 mb-2 cm-sidebar-save-btn bg-action-blue text-white d-flex align-items-center justify-content-center cm-pointer" onClick={handleMarkFlag}>Mark Flag</button>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="d-flex align-items-center border-top border-blue-gray-200 justify-content-between  py-2  cm-comment-row-2">
                        <p className="mb-0 text-blue-800 fw-medium ms-3" >Comments</p>

                        <div className="cm-commet-filters">
                            <label className="me-2"> Resolved Filter </label>
                            <Muiselect
                                id="comment-filters"
                                value={commentFilter}
                                onChange={e => handleChangeFilter(e)}>
                                {loadSelectMenuItem(commentOptions)}
                            </Muiselect>
                        </div>
                    </div>
                    {(
                        !commentModal.isLoading ? ((commentModal.comments?.length > 0 && comments?.length > 0) ? comments?.map((el, index) => <div key={el.commentId} className={`d-flex justify-content-center bg-blue-gray-50 border border-blue-gray-50 p-2 rounded mb-3 shadow-sm`}>
                            <div className="cm-avatar fw-semibold text-white d-flex align-items-center justify-content-center bg-purple text-uppercase">{getInitials(el.commenterName)}</div>
                            <div className="cm-comment-body cm-sm-txt ms-3">
                                <p className="mb-0"><span className="fw-semibold text-blue-800">{el.commenterName}</span></p>
                                <p className="mb-2 text-blue-gray-700 cm-xs-txt">on {moment(el.commentedOn).format("DD MMM YYYY [at] HH:mm")} | marked <CustomToolTip title={el.flagText}><FontAwesomeIcon icon={solid("flag")} style={{ color: el.flagColour }} className="ms-2" /></CustomToolTip></p>
                                {
                                    el.isFromOldCms &&
                                    <p className="text-blue-gray-700 cm-xs-txt mb-2">
                                        <div>Old Cms commented By : {el.oldCmsCreatedByEmail}</div>
                                        {
                                            el.oldCmsResolvedByEmail && <div className="clear">Old Cms Resolved By : {el.oldCmsResolvedByEmail}</div>
                                        }
                                    </p>
                                }
                                <p className="cm-sm-txt fwq-medium text-blue-800 mb-0">{el.commentData}</p>
                            </div>
                            <div className="cm-resolve-comment d-flex justify-content-end">
                                {(!commentMap?.get(el.commentId)) ? (<span className={`text-red  ${user.role === "ADMIN" ? "cm-pointer" : "disabledclass"}`} onClick={() => { handleResolveComment(el.commentId) }}> Resolve This</span>) : (<div ><p className="text-green"> Resolved</p>
                                    <p> Resolved By : {el.resolvedName ? <span className="fw-semibold text-blue-800">{el.resolvedName}</span> : <span className="fw-semibold text-blue-800">{user.firstName + " " + user.lastName}</span>}</p></div>)
                                }
                            </div>
                        </div>) : <NoResultsPage t={t} searchActive={false} searchTerm={null} resetFn={null} noDataSubHead="No comments to see. Start by adding a comment." txtClasses="cm-sm-txt" />) : <AnimationDataLoader />
                    )}
                </div>
                <div className="d-flex align-items-center justify-content-end border-top border-blue-gray-300 px-3 py-2 cm-comment-row-2">
                    {/* {<span onClick={() => handleFormView(commentModal.formView === "comment" ? "form" : "comment")} className={`bg-action-blue border border-action-blue text-white cm-sm-txt fw-medium px-3 rounded py-2 d-flex align-items-center cm-pointer d-inline-block me-3 ${!getPermission("canComment") ? "cm-disabled" : ""}`}><FontAwesomeIcon icon={commentModal.formView === "comment" ? regular("plus") : regular("comments")} className="pe-2 cm-pointer" /> {commentModal.formView === "comment" ? "Add Comment" : "View Comments"}</span>} */}
                    <span className="d-inline-block bg-white border border-blue-gray-100 text-blue-800 cm-sm-txt fw-medium px-3 rounded py-2 cm-pointer" onClick={handleCloseModal}>Close</span>
                </div>
            </div>
            }
        </div >
    )
}
export default BankQuestionPreviewCard;