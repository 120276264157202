import React from 'react'
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimationDataLoader from '../../components/Loaders/animationData';

const OtherTeamAssignmentModal = ({ t, modalData, handleBankModalToggle }) => {
    const dispatch = useDispatch();
    const [teamData, setTeamData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);


    React.useEffect(() => {
        getOtherTeamShareBankStatus();
    }, [modalData]);

    const getOtherTeamShareBankStatus = async () => {
        try {
            const response = await instance.get(`${getApiUrl('getOtherTeamShareBankStatus')}?rootBankId=${modalData.reviewBankId}`);
            if (response.data) {
                setTeamData(response.data);
                setIsLoading(false);
            }
        }
        catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
            // setIsLoading(false);
        }
    }

    const handleChange = (value, index) => {
        const nextTeamData = [...teamData];
        nextTeamData[index].isShared = !nextTeamData[index].isShared;
        setTeamData(nextTeamData);
    }

    const handleTeamAssignSubmit = async () => {
        try {
            let teamIds = [];

            teamData.map(el => {
                if (el.isShared) {
                    teamIds.push(el.teamId);
                }
            })

            const res = await instance.get(`${getApiUrl('shareBankWithOthers')}?rootBankId=${modalData.reviewBankId}&teamIds=${teamIds.toString()}`);

            if (res.data?.status) {
                dispatch(openSnackBar({
                    msg: res.data?.message,
                    type: 'success'
                }))
                handleBankModalToggle();
            }
        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    return (
        <>{
            isLoading ? <div className='d-flex justify-content-center'><AnimationDataLoader /> </div> : <>
                <div className="d-flex flex-wrap py-3 px-3">
                    {
                        teamData.map((el, i) => <div className='mb-2 col-md-4 align-items-center user-select-none' key={el.teamId}>
                            <input type="checkbox" name={el.teamName} id={el.teamId} checked={el.isShared} onChange={() => handleChange(el.teamId, i)} />
                            <label htmlFor={el.teamName} className='ps-2 cm-sm-txt fw-medium'>{el.teamName}</label>
                        </div>)
                    }
                </div>
                <div className="cm-form-btn-group py-2 cm-create-modal-form-footer d-flex align-items-center justify-content-end">
                    <button
                        onClick={handleTeamAssignSubmit}
                        className={`cm-icon-btn position-relative btn btn-primary bg-action-blue cm-sm-txt cm-btn border border-action-blue cm-prim-btn d-flex align-items-center`}>
                        <div>
                            <span className="text-white">Share Bank</span>
                            <FontAwesomeIcon icon={regular('angle-right')} className="ps-2" />
                        </div >
                    </button>
                </div>
            </>
        }</>
    )

}

export default OtherTeamAssignmentModal;