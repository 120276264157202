import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useDispatch } from 'react-redux';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import AnimationDataLoader from '../../components/Loaders/animationData';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';
import { getColourCodeFromName } from '../../utils/helperFn';

const PermStatus = ({ status }) => {
    return <FontAwesomeIcon icon={status ? regular("check-circle") : regular("times-circle")} className={status ? 'text-success' : ' text-danger'} />
}

const ViewAssignment = ({ t, role, modalData, handleBankModalToggle }) => {
    const dispatch = useDispatch();
    const [deleteDialog, setDeleteDialog] = React.useState({
        status: false,
        data: null
    })

    const [assignmentData, setAssigmentData] = React.useState({
        isLoading: true,
        data: null
    })

    React.useEffect(() => {
        if ((role === "ADMIN" && modalData.assignedCount > 0) || (role == "SME")) {
            getAllBankAssignment();
        }
    }, [modalData]);

    const getAllBankAssignment = async () => {
        try {
            const res = await instance.get(`${getApiUrl("getAllBankAssignment")}?bankId=${modalData.reviewBankId}`);

            if (res.data) {
                setAssigmentData({
                    ...assignmentData,
                    isLoading: false,
                    data: res.data
                })
            }
        } catch (error) {
            setAssigmentData({
                ...assignmentData,
                isLoading: false,
            })

            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleDelete = async (userId) => {

        try {
            const response = await instance.get(`${getApiUrl('deleteBankAssignment')}?bankId=${modalData.reviewBankId}&assignedTo=${userId}`);

            if (response.data?.status) {
                setAssigmentData({
                    ...assignmentData,
                    data: assignmentData.data.filter(el => el.assignedTo !== userId)
                });

                console.log(assignmentData.data.filter(el => el.assignedTo !== userId));

                handleBankModalToggle('DELETE_BANK_ASSIGNMENT_SUCCESS', {
                    reviewBankId: modalData.reviewBankId,
                    assignedUsers: assignmentData.data.filter(el => el.assignedTo !== userId).map(el => {
                        return {
                            assignedTo: el.assignedTo,
                            emailId: el.emailId,
                            name: el.name,
                            colour: getColourCodeFromName(el.name)
                        }
                    })
                });

                dispatch(openSnackBar({
                    msg: response.data?.message,
                    type: 'success'
                }))
            }
        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleDialogToggle = (data) => {
        setDeleteDialog({
            ...deleteDialog,
            status: !deleteDialog.status,
            data: !!data ? data : null
        })
    }

    const dialogActionFn = () => {
        handleDelete(deleteDialog.data.assignedTo);
        handleDialogToggle();
    }

    return (
        <div className="mt-3 cm-create-task-form px-4 pb-4">
            {
                !assignmentData.isLoading ? assignmentData.data?.length > 0 ? <>
                    <table className='cm-sm-txt fw-medium w-100'>
                        <thead>
                            <tr className='text-action-blue fw-semibold bg-blue-gray-50'>
                                <th className='border border-blue-gray-100 p-2'>User Name</th>
                                <th className='border border-blue-gray-100 p-2'>Allow Comment</th>
                                <th className='border border-blue-gray-100 p-2'>View Other Comments</th>
                                <th className='border border-blue-gray-100 p-2'>View Other Commenter Detail</th>
                                <th className='border border-blue-gray-100 p-2'>View Solutions</th>
                                <th className='border border-blue-gray-100 p-2'>Download Question Bank</th>
                                {role === "ADMIN" && <th className='border border-blue-gray-100 p-2'>Remove Assignment from Client</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {assignmentData.data.map((el) => <tr key={`${el.assignedTo}`} className="text-blue-gray-700">
                                <td className='p-2 border border-blue-gray-100'><p className='cm-sm-txt fw-medium mb-0'>{el.name}</p> <p className='cm-sm-txt fw-medium mb-0'>{el.emailId}</p></td>
                                <td className='p-2 text-center border border-blue-gray-100'><PermStatus status={el.reviewBankPermissionDTO.canComment} /></td>
                                <td className='p-2 text-center border border-blue-gray-100'><PermStatus status={el.reviewBankPermissionDTO.canViewComment} /></td>
                                <td className='p-2 text-center border border-blue-gray-100'><PermStatus status={el.reviewBankPermissionDTO.canViewCommenterDetails} /></td>
                                <td className='p-2 text-center border border-blue-gray-100'><PermStatus status={el.reviewBankPermissionDTO.canViewSolution} /></td>
                                <td className='p-2 text-center border border-blue-gray-100'><PermStatus status={el.reviewBankPermissionDTO.canDownloadQuestion} /></td>
                                {role === "ADMIN" && <td className='p-2 text-center border border-blue-gray-100'><FontAwesomeIcon icon={regular("trash")} onClick={() => handleDialogToggle(el)} className="text-danger cm-pointer" /></td>}
                            </tr>)}
                        </tbody>
                    </table>
                    <CustomDialog
                        dialogHeading="Remove Assignment from Client"
                        cancelBtnLabel="No Close Panel"
                        actionBtnBg="bg-danger"
                        actionBtnLabel={<span>Revoke Permissions</span>}
                        cancelFn={() => handleDialogToggle("exit_dialog")}
                        actionFn={() => dialogActionFn("exit_dialog")}
                        dialogStatus={deleteDialog.status}
                    >
                        <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to remove assignment from:</p>
                        <ul className='ps-3 m-0 cm-sm-txt text-blue-800'>
                            <li><span className='fw-semibold'>{deleteDialog.data?.name} {`<${deleteDialog.data?.emailId}>`}</span></li>
                        </ul>
                    </CustomDialog>
                </> : <p className='text-blue-gray-700 text-center cm-sm-txt fw-medium mt-3 mb-0'>No client assigned yet.</p>
                    : <div className='d-flex justify-content-center'><AnimationDataLoader /> </div>
            }
        </div>
    )

}

export default ViewAssignment;