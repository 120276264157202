
export const searchFilter = function (arr, search, keys) {
    var lowSearch = search.toLowerCase();
    return arr.filter(el =>
        keys.some(key =>
            String(el[key]).toLowerCase().includes(lowSearch)
        )
    );
}

//Add leading zero to single digit number
export const addPad = (num) => String(num).padStart(2, '0');

export const getInitials = (str) => {

    if (!str) return '';

    // eslint-disable-next-line
    return str.split(' ').map((element, index, array) => {
        if (index === 0 || index === array.length - 1) {
            return element[0]
        }
    }).join('')
}

export const checkPassword = (str) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    return re.test(str);
}

export const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export const handleCodeChange = (editorStateHtml, id) => {
    let updatedhtml = editorStateHtml;
    updatedhtml = editorStateHtml?.replace('&amp;mode=', 'mode=');
    updatedhtml = updatedhtml?.replace('&amp;code=', '&code=');
    let iframes = document.querySelectorAll(`.editor-instance #cke_${id} iframe`);
    if (iframes.length > 0) {
        let parsedHTMLData = parseHTML(updatedhtml);
        let parsedIframeData = parsedHTMLData.getElementsByTagName('iframe')
        iframes.forEach((el, index) => {
            let dom = el.contentWindow.document.body;
            let currVal = dom.querySelector(".CodeMirror").CodeMirror.getValue();
            let srcUrl = parsedIframeData[index].getAttribute("src")
            var startIndex = srcUrl.indexOf("mode=") + 5;
            var endIndex = srcUrl.indexOf("&code", startIndex);
            let currentMode = srcUrl.substring(startIndex, endIndex);
            updatedhtml = updatedhtml?.replace(srcUrl, "/corporate/question/codesnippet?mode=" + currentMode + "&code=" + encodeURIComponent(currVal));
        })
    }


    let ckeditorInstanceSnapshot = window.CKEDITOR.instances[id];
    var firstDiv = document.createElement('div');
    firstDiv.innerHTML = ckeditorInstanceSnapshot.getSnapshot();
    let wirisImages = Array.from(firstDiv.getElementsByClassName('Wirisformula'));

    var secondDiv = document.createElement('div');
    secondDiv.innerHTML = updatedhtml
    let mathElems = Array.from(secondDiv.getElementsByTagName('math'))
    if (mathElems.length > 0) {
        for (var i = 0; i < mathElems.length; i++) {
            wirisImages[i].classList.add('_mathMLClass');
            mathElems[i].replaceWith(wirisImages[i]);
        }
    }
    return secondDiv.innerHTML;
}

export const getEditorDataById = (id) => {
    const editor = window.CKEDITOR.instances[id];

    if (editor) {
        return handleCodeChange(editor.getData(), id)
    } else {
        return ''
    }

}

export const objectsAreEqualWithNull = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const value1 = obj1[key];
        const value2 = obj2[key];

        if (Array.isArray(value1) && Array.isArray(value2)) {
            const array2Sorted = value2.slice().sort();
            value1.length === value2.length && value1.slice().sort().every(function (value, index) {
                if (value !== array2Sorted[index])
                    return false;
            });
        } else if (value1 !== value2 && (value1 !== null || value2 !== null)) {
            return false;
        }
    }

    return true;
}

export const splitLines = str => str.split(/\r?\n/);

export const getColourCodeFromName = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.codePointAt(i) + ((hash << 5) - hash);
    }
    let c = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    return ("00000".substring(0, 6 - c.length) + c);
}

function parseHTML(html) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, "text/html");
    return doc;
}

export const addIframeLazyLoading=(stringText)=>{
    return stringText.replace(new RegExp("<iframe", 'g'), `<iframe loading="lazy"`);
}